<template>
  <v-app id="app">
    <v-app-bar color="primary" class="flex-grow-0" app dark>
      <v-spacer/>
      <a href="/">
        <img src="./assets/wbstore.png" height="50px" />
      </a>
      <v-spacer/>
    </v-app-bar>
    <v-main style="background-color: rgb(238, 238, 238);">
      <router-view />
    </v-main>
      <v-footer
          color="primary lighten-1"
          padless
      >
        <v-row
            justify="center"
            no-gutters
        >
          <v-btn
              color="white"
              text
              rounded
              class="my-2"
              to="/politique-confidentialite"
          >
           Politique de confidentialité
          </v-btn>
          <v-btn
              color="white"
              text
              rounded
              class="my-2"
              to="/politiques-traitement"
          >
            Politiques de traitement
          </v-btn>
          <v-btn
              color="white"
              text
              rounded
              class="my-2"
              to="/service-client"
          >
            Service Client
          </v-btn>
          <v-col
              class="primary lighten-2 py-4 text-center white--text"
              cols="12"
          >
            {{ new Date().getFullYear() }} — <strong> Wrestling Bet</strong>
          </v-col>
        </v-row>
      </v-footer>
  </v-app>
</template>
<script>
import {getAuthToken, isLoggedIn, logOut} from './utils/auth'
import axios from "axios";

export default {
  data () {
    return {
      user: null
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn()
    },
    logOut() {
      this.$router.push('/login')
      return logOut()
    },
    getUserInfo () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/user/',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => {
        this.user = response.data
      });
    },
  },
  mounted () {
    if(getAuthToken() !== null) {
      this.getUserInfo()
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.cagnotte-img{
  padding-right:5px;
}
.cagnotte-montant{
  padding-left:0;
  font-weight: 800;
}
</style>
