import Vue from "vue";
import VueRouter from "vue-router";
import WBPolitiqueConfidentialite from "../views/WBPolitiqueConfidentialite.vue";
import WBPolitiquesTraitement from "../views/WBPolitiquesTraitement.vue";
import WBServiceClient from "../views/WBServiceClient.vue";
import WBLogin from "../views/WBLogin.vue";
import WBSuccess from "../views/WBSuccess.vue";
import WBError from "../views/WBError.vue";
import WBResetPassword from "../views/WBResetPassword.vue";
import WBRequestNewPassword from "../views/WBRequestNewPassword.vue";
import WBDashboard from "../views/WBDashboard.vue";
import WBCards from "../views/WBCards.vue";
import WBCredits from "../views/WBCredits.vue";
import WBPremium from "../views/WBPremium.vue";
import { isLoggedIn } from '../utils/auth'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "WBCards",
    component: WBCards
  },
  {
    path: "/success",
    name: "WBSuccess",
    component: WBSuccess
  },
  {
    path: "/error",
    name: "WBError",
    component: WBError
  },
  {
    path: "/password",
    name: "WBResetPassword",
    component: WBResetPassword,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/request-new-password",
    name: "WBRequestNewPassword",
    component: WBRequestNewPassword,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/credits",
    name: "WBCredits",
    component: WBCredits
  },
  {
    path: "/cards",
    name: "WBCards",
    component: WBCards
  },
  {
    path: "/premium",
    name: "WBPremium",
    component: WBPremium
  },
  {
    path: "/login",
    name: "WBLogin",
    component: WBLogin,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/politiques-traitement",
    name: "WBPolitiquesTraitement",
    component: WBPolitiquesTraitement,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/politique-confidentialite",
    name: "WBPolitiqueConfidentialite",
    component: WBPolitiqueConfidentialite,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/service-client",
    name: "WBServiceClient",
    component: WBServiceClient,
    meta: {
      allowAnonymous: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == 'login' && isLoggedIn()) {
    next({ path: '/credits' })
  }
  else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})
export default router;
