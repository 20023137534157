<template>
  <v-container>
    <h1>Mot de passe oublié</h1>
    <v-alert
        v-if="success"
        dense
        text
        type="success"
    >
      Mot de passe modifié avec succès. Vous pouvez vous connecter avec votre nouveau mot de passe à l'application Wrestling Bet.
    </v-alert>
    <v-row v-if="token && !success">
    <v-col
        cols="12"
    >
      <v-text-field
          label="Nouveau mot de passe"
          v-model="password"
          type="password"
          outlined
          clearable
      ></v-text-field>
      <v-text-field
          v-model="passwordConfirm"
          label="Confirmez votre nouveau mot de passe"
          outlined
          type="password"
          clearable
      ></v-text-field>
      <div v-if="this.password != null && this.passwordConfirm != null && this.password != this.passwordConfirm" style="color:red;">
        Les mots de passes ne correspondent pas. Veuillez corriger.
      </div>
      <br>
      <v-btn v-if="!loader" @click="resetPassword()" color="primary" :disabled="this.password != null && this.passwordConfirm != null && this.password != this.passwordConfirm">
        Confirmer
      </v-btn>
      <v-progress-circular
          v-if="loader"
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-col>
    </v-row>
    <v-snackbar
        v-model="error"
        color="error"
    >
     Erreur. Impossible de modifier le mot de passe.
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
        token: null,
        password: null,
        passwordConfirm: null,
        success: false,
        error: false,
        loader: false
    };
  },
  async mounted () {
    this.token = this.$route.query.token
  },
  methods: {
    resetPassword () {
      this.loader = true
        axios({
          method: 'put',
          url: process.env.VUE_APP_API_ROOT_URL + '/user/password?resetToken=' + this.token + '&newPassword=' + this.password
        }).then(() => this.success = true).catch(() => this.error = true);
      }
  },
};
</script>
