<template>
  <v-container>
  <v-row>
    <v-col cols="12" style="margin-top:30px;">
      <img src="../assets/wbet5.png" class="coffre" />
      <img src="../assets/wbet4.png" class="sac" />
      <img src="../assets/wbgoldicon.png" class="logo" />
      <img src="../assets/phone.png" class="phone" />
    </v-col>
    <v-col cols="11" sm="3" class="wb-form">
      <form>
        <v-text-field
            v-model="username"
            label="Identifiant"
            outlined
        ></v-text-field>
        <v-text-field
            v-model="password"
            label="Mot de passe"
            type="password"
            outlined
        ></v-text-field>
        <v-btn color="primary" v-on:click="login" v-if="!loader">Connexion</v-btn>
        <v-progress-circular
            v-if="loader"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </form>
      <br>
      <a href="/request-new-password">Mot de passe oublié ?</a>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import axios from 'axios';
import {loginUser, setAuthToken} from '../utils/auth'

export default {
  name: 'WBLogin',
  data: () => {
    return {
      username: "",
      password: "",
      loader: false
    };
  },
  mounted () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')
    const redirect = urlParams.get('redirect')
    if(token) {
      setAuthToken(token);
      if(redirect) {
        this.$router.push(redirect)
      } else {
        this.$router.push('/')
      }
    }
    },
  methods: {
    ...mapMutations(["setUser", "setToken"]),
    authIsValid () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/authenticate',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function () {
        return true
      }).catch(function () {
        return false
      });
    },
    async login() {
      try {
        await loginUser(this.username, this.password)
        this.loader = true
        this.$router.push('/')
      }
      catch (err) {
        this.loader = false;
        alert(`Error: ${err}`)
      }
    },
  },
};
</script>
<style scoped>
.wb-form{
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
.coffre{
  position:absolute !important;
  margin-left:-70px;
  width:220px;
}
.sac{
  position: absolute !important;
  margin-left: 90px;
  width: 220px;
}
.phone{
  z-index:10;
  width:200px;
  animation: zoom-in-zoom-out 5s ease infinite;
}
.logo{
  z-index:11;
  position:absolute;
  width:200px;
  margin-left:50px;
  margin-top:50px;
  animation:rotate-wb 5s ease infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1) rotate(0deg);
  }
  25% {
    transform: scale(1.2, 1.2) rotate(8deg);
  }
  50% {
    transform: scale(1, 1) rotate(0deg);
  }
  75% {
    transform: scale(1.2, 1.2) rotate(-8deg);
  }
  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

@keyframes rotate-wb {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
