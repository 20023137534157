import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import vuetify from '@/plugins/vuetify' // path to vuetify export
import moment from 'moment'
import VueI18n from 'vue-i18n'

import DatetimePicker from 'vuetify-datetime-picker'


Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
})
Vue.use(VueI18n)
Vue.use(DatetimePicker)
Vue.config.productionTip = false;

const messages = {
  en: {
    message: {
      hello: 'Hello {user}',
      credits: 'Credits',
      cards: 'Cards'
    }
  },
  fr: {
    message: {
      hello: 'Bonjour {user}',
      credits: 'Crédits',
      cards: 'Cartes'
    }
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: navigator.language === 'fr-FR' ? 'fr' : 'en', // set locale
  messages, // set locale messages
})

new Vue({
  router,
  store,
  axios,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
