<template>
  <v-container>
    <v-row align="left" justify="left">
      <v-col>
        <v-alert
            dense
            text
            type="success"
            style="text-align:left;"
        >
          <strong>Félicitations ! </strong> Votre achat a bien été effectué !
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <img src="../assets/crown.png" class="animated-image"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn to="/" style="font-family: 'Staatliches', cursive;font-size:24px;" color="primary"><v-icon left>
          mdi-arrow-left
        </v-icon>
          RETOUR À LA BOUTIQUE</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  data () {
    return {
      user: null
    };
  },
  async mounted () {
    this.getUserInfo()
  },
  methods: {
    getUserInfo () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/user/',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => {
        this.user = response.data
      }).finally(() => {
        this.getProducts(this.user.membreNiveau.id.niveau.id)
      });
    }
  },
};
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
.animated-image{
  width:300px;
  animation: zoom-in-zoom-out 5s ease infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1) rotate(0deg);
  }
  25% {
    transform: scale(1.2, 1.2) rotate(20deg);
  }
  50% {
    transform: scale(1, 1) rotate(0deg);
  }
  75% {
    transform: scale(1.2, 1.2) rotate(-20deg);
  }
  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}
</style>
