<template>
  <v-container>
    <h1>Réinitialiser mon mot de passe</h1>
    <v-alert
        v-if="success"
        dense
        text
        type="success"
    >
      Mail envoyé avec succès. Pensez à regarder vos SPAM.
    </v-alert>
    <v-row v-if="!success">
    <v-col
        cols="12"
    >
      <v-text-field
          label="Adresse email de votre compte Wrestling Bet"
          outlined
          v-model="email"
          clearable
      ></v-text-field>
      <v-btn color="primary" @click="sendEmail()" v-if="!loader">
        Réinitialiser mon mot de passe
      </v-btn>
      <v-progress-circular
          v-if="loader"
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-col>
    </v-row>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. Impossible d'envoyer le mail à cette adresse.
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
        token: null,
        email: null,
      success: false,
      error: false,
      loader: false
    };
  },
  async mounted () {
  },
  methods: {
    sendEmail () {
      this.loader = true
      axios({
        method: 'put',
        url: process.env.VUE_APP_API_ROOT_URL + '/user/send-reset-link?email=' + this.email
      }).then(() => {
        this.success = true
      }).catch(() => this.error = true);
    }
  },
};
</script>
