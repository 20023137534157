<template>
  <div>
    <stripe-checkout
        v-if="user"
        ref="creditsRef"
        mode="payment"
        :pk="publishableKey"
        :customerEmail="user.email"
        :line-items="credits"
        :success-url="successURL"
        :cancel-url="cancelURL"
        :clientReferenceId="user.username"
        @loading="v => loading = v"
    />
    <v-container v-if="user">
      <v-row align="center"
             justify="center" style="margin-top:0px;">
        <v-col cols="12" md="4" style="padding:5px;">
          <v-alert color="primary" dense style="font-weight:800; color:white; margin:0;font-family: 'Staatliches', cursive;font-size:28px;">
            {{ $t('message.hello', { user: user.username }) }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs style="border-radius: 5px;">
        <v-tab to="/cards" href="/cards">{{ $t('message.cards') }}</v-tab>
        <v-tab to="/premium" href="/premium">PREMIUM</v-tab>
        <v-tab to="/credits" href="/credits">{{ $t('message.credits') }}</v-tab>
      </v-tabs>
      <section style="background-color: #eee;">
        <div class="container py-5">
          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-4 mb-4 mb-lg-0" v-for="(item, index) in products" v-bind:key="index">
              <div class="card" style="border-radius: 15px;min-height: 100%;" @click="acheterCredits(item.stripeId)">
                <div class="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
                     data-mdb-ripple-color="light">
                  <img :src="item.image"
                       width="70%"
                       style="border-top-left-radius: 15px; border-top-right-radius: 15px;" class="img-fluid"
                       alt="Laptop" />
                  <a @click="acheterCredits(item.stripeId)">
                    <div class="mask"></div>
                  </a>
                </div>
                <div class="card-body pb-0">
                  <div class="d-flex justify-content-between">
                    <div>
                      <p><a href="#!" class="text-dark" style="font-size: 25px;font-weight: 700;">{{  getNavigator() === 'fr-FR' ? item.libelleFr : item.libelleEn }}</a></p>
                      <p class="small text-muted" v-html="getNavigator() === 'fr-FR' ? item.descriptionFr : item.descriptionEn"></p>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <hr class="my-0" />
                <div class="card-body pb-0">
                  <div class="d-flex justify-content-between">
                    <p><a href="#!" class="text-dark"></a></p>
                    <p class="text-dark" style="font-size: 20px;font-weight: 700;">{{ item.montant }}€</p>
                  </div>
                </div>
                <hr class="my-0" />
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center pb-2 mb-1">
                    <a href="#!" class="text-dark fw-bold"></a>
                    <button type="button" class="btn btn-primary" @click="acheterCredits(item.stripeId)" style="color:white;background-color: #4252b1">{{  getNavigator() === 'fr-FR' ? 'Acheter' : 'Buy' }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </v-container>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  components: {
    StripeCheckout,
  },
  data () {
    return {
      publishableKey: "pk_live_51JVK6vLynYNoaybdZFtMVGSH7YRoG1YIEEOuk3fi0Q2wKQivdcG2Sn8UGp3aLMuJLxGGEgw3Y9q1MomGYmIDAcxO000vUC8AWd",
      loading: false,
      credits: [],
      successURL: 'https://store.wrestling-bet.com/success',
      cancelURL: 'https://store.wrestling-bet.com/error',
      user: null,
      products: []
    };
  },
  async mounted () {
      this.getUserInfo()
      this.getProducts()
  },
  methods: {
    acheterCredits (stripeId) {
      this.credits = [
        {
          price: stripeId,
          quantity: 1,
        }
      ]
      // You will be redirected to Stripe's secure checkout page
      this.$refs.creditsRef.redirectToCheckout();
    },
    getUserInfo () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/user/',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => {
        this.user = response.data
      }).finally(() => {
        this.getProducts(this.user.niveau.id)
      });
    },
    getNavigator () {
      return navigator.language;
    },
    getProducts (niveau) {
      if (niveau !== undefined) {
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_ROOT_URL + '/api/catalogue/niveau/' + niveau,
          headers: {
            'Authorization': 'Bearer ' + getAuthToken()
          }
        }).then((response) => this.products = response.data);
      }
    }
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
</style>
