<template>
  <div>
    <stripe-checkout
        v-if="user"
        ref="creditsRef"
        mode="payment"
        :pk="publishableKey"
        :customerEmail="user.email"
        :line-items="credits"
        :success-url="successURL"
        :cancel-url="cancelURL"
        :clientReferenceId="user.username"
        @loading="v => loading = v"
    />
    <v-container v-if="user" style="background-color: rgb(238, 238, 238);">
      <v-row align="center"
             justify="center" style="margin-top:0px;">
        <v-col cols="12" md="4" style="padding:5px;">
          <v-alert color="primary" dense style="font-weight:800; color:white; margin:0;font-family: 'Staatliches', cursive;font-size:28px;">
            {{ $t('message.hello', { user: user.username }) }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs style="border-radius: 5px;">
        <v-tab to="/cards" href="/cards">{{ $t('message.cards') }}</v-tab>
        <v-tab to="/premium" href="/premium">PREMIUM</v-tab>
        <v-tab to="/credits" href="/credits">{{ $t('message.credits') }}</v-tab>
      </v-tabs>
          <section style="background-color: #eee;">
            <div class="container py-5">
              <div class="row justify-content-center">
                <div class="col-md-12 col-lg-4 mb-4 mb-lg-0" v-for="(item, index) in getAllProducts()" v-bind:key="index">
                  <div class="card" style="border-radius: 15px;" @click="acheterCredits(item.stripeId)">
                    <div class="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
                         data-mdb-ripple-color="light">
                      <img :src="item.image"
                           width="70%"
                           style="border-top-left-radius: 15px; border-top-right-radius: 15px;" class="img-fluid"
                           alt="Laptop" />
                      <a @click="acheterCredits(item.stripeId)">
                        <div class="mask"></div>
                      </a>
                    </div>
                    <div class="card-body pb-0">
                      <div class="d-flex justify-content-between">
                        <div>
                          <p><a href="#!" class="text-dark" style="font-size: 25px;font-weight: 700;">{{  getNavigator() === 'fr-FR' ? item.libelleFr : item.libelleEn }}</a></p>
                          <p class="small text-muted" v-html="getNavigator() === 'fr-FR' ? item.descriptionFr : item.descriptionEn"></p>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                    <hr class="my-0" />
                    <div class="card-body pb-0">
                      <div class="d-flex justify-content-between">
                        <p><a href="#!" class="text-dark"></a></p>
                        <p class="text-dark" style="font-size: 20px;font-weight: 700;">{{ item.montant }}€</p>
                      </div>
                    </div>
                    <hr class="my-0" />
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center pb-2 mb-1">
                        <a href="#!" class="text-dark fw-bold"></a>
                        <button type="button" class="btn btn-primary" @click="acheterCredits(item.stripeId)" style="color:white; background-color: #4252b1">{{  getNavigator() === 'fr-FR' ? 'Acheter' : 'Buy' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    </v-container>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  components: {
    StripeCheckout,
  },
  data () {
    return {
      publishableKey: "pk_live_51JVK6vLynYNoaybdZFtMVGSH7YRoG1YIEEOuk3fi0Q2wKQivdcG2Sn8UGp3aLMuJLxGGEgw3Y9q1MomGYmIDAcxO000vUC8AWd",
      loading: false,
      credits: [],
      successURL: 'https://store.wrestling-bet.com/success',
      cancelURL: 'https://store.wrestling-bet.com/error',
      user: null,
      products: [],
      allProducts: []
    };
  },
  async mounted () {
    this.getUserInfo()
    this.getProducts()
  },
  methods: {
    getNavigator () {
      return navigator.language;
    },
    getAllProducts () {
      return [
        {
          stripeId: 'price_1LR8WvLynYNoaybdwAvFcs7G',
          montant: '3.75',
          oldMontant: '4.99',
          libelleFr: 'Premium - 1 mois',
          libelleEn: 'Premium - 1 month',
          image: 'https://i.ibb.co/7jrHSd8/wbpremium2023.png',
          descriptionFr:
              'Contenu du pack : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>Aucune publicité au lancement de l\'application</li>' +
              '<li>Validation des paris sans visionner de publicité</li>' +
              '<li>Des crédits offerts tous les jours automatiquement (de 100 à 550 crédits/jour en fonction de votre niveau)</li>' +
              '<li>L\'application aux couleurs d\'un champion avec le thème GOLD</li>' +
              '<li>Badge Premium Gold à côté de votre pseudo dans le classement</li>' +
              '</ul>' +
              '<br>Ceci est un achat unique, aucun renouvellement ne sera effectué lors de l\'expiration du compte Premium.',
          descriptionEn:
              'Pack content : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>No ads when launching the application</li>' +
              '<li>Validation of bets without viewing advertising</li>' +
              '<li>Credits offered every day automatically (from 100 to 550 credits/day depending on your level)</li>' +
              '<li>The application in the colors of a champion with the GOLD theme</li>' +
              '<li>Premium Gold badge next to your nickname on the leaderboard</li>' +
              '</ul>' +
              '<br>This is a one-time purchase, no renewals will be made when the Premium Account expires.'
        },

        {
          stripeId: 'price_1LR8Y0LynYNoaybd6z7nD7lR',
          montant: '9.75',
          oldMontant: '14.99',
          libelleFr: 'Premium - 3 mois',
          libelleEn: 'Premium - 3 months',
          image: 'https://i.ibb.co/7jrHSd8/wbpremium2023.png',
          descriptionFr:
              'Contenu du pack : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>Aucune publicité au lancement de l\'application</li>' +
              '<li>Validation des paris sans visionner de publicité</li>' +
              '<li>Des crédits offerts tous les jours automatiquement (de 100 à 550 crédits/jour en fonction de votre niveau)</li>' +
              '<li>L\'application aux couleurs d\'un champion avec le thème GOLD</li>' +
              '<li>Badge Premium Gold à côté de votre pseudo dans le classement</li>' +
              '</ul>' +
              '<br>Ceci est un achat unique, aucun renouvellement ne sera effectué lors de l\'expiration du compte Premium.',
          descriptionEn:
              'Pack content : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>No ads when launching the application</li>' +
              '<li>Validation of bets without viewing advertising</li>' +
              '<li>Credits offered every day automatically (from 100 to 550 credits/day depending on your level)</li>' +
              '<li>The application in the colors of a champion with the GOLD theme</li>' +
              '<li>Premium Gold badge next to your nickname on the leaderboard</li>' +
              '</ul>' +
              '<br>This is a one-time purchase, no renewals will be made when the Premium Account expires.'
        },
        {
          stripeId: 'price_1LR8YpLynYNoaybdwkDQfrb0',
          montant: '17.99',
          oldMontant: '29.99',
          libelleFr: 'Premium - 6 mois',
          libelleEn: 'Premium - 6 months',
          image: 'https://i.ibb.co/7jrHSd8/wbpremium2023.png',
          descriptionFr:
              'Contenu du pack : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>Aucune publicité au lancement de l\'application</li>' +
              '<li>Validation des paris sans visionner de publicité</li>' +
              '<li>Des crédits offerts tous les jours automatiquement (de 100 à 550 crédits/jour en fonction de votre niveau)</li>' +
              '<li>L\'application aux couleurs d\'un champion avec le thème GOLD</li>' +
              '<li>Badge Premium Gold à côté de votre pseudo dans le classement</li>' +
              '</ul>' +
              '<br>Ceci est un achat unique, aucun renouvellement ne sera effectué lors de l\'expiration du compte Premium.',
          descriptionEn:
              'Pack content : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>No ads when launching the application</li>' +
              '<li>Validation of bets without viewing advertising</li>' +
              '<li>Credits offered every day automatically (from 100 to 550 credits/day depending on your level)</li>' +
              '<li>The application in the colors of a champion with the GOLD theme</li>' +
              '<li>Premium Gold badge next to your nickname on the leaderboard</li>' +
              '</ul>' +
              '<br>This is a one-time purchase, no renewals will be made when the Premium Account expires.'
        },
        {
          stripeId: 'price_1LR8ZHLynYNoaybdUzziLi8W',
          montant: '34.49',
          oldMontant: '59.99',
          libelleFr: 'Premium - 1 an',
          libelleEn: 'Premium - 1 year',
          image: 'https://i.ibb.co/7jrHSd8/wbpremium2023.png',
          descriptionFr:
              'Contenu du pack : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>Aucune publicité au lancement de l\'application</li>' +
              '<li>Validation des paris sans visionner de publicité</li>' +
              '<li>Des crédits offerts tous les jours automatiquement (de 100 à 550 crédits/jour en fonction de votre niveau)</li>' +
              '<li>L\'application aux couleurs d\'un champion avec le thème GOLD</li>' +
              '<li>Badge Premium Gold à côté de votre pseudo dans le classement</li>' +
              '</ul>' +
              '<br>Ceci est un achat unique, aucun renouvellement ne sera effectué lors de l\'expiration du compte Premium.',
          descriptionEn:
              'Pack content : <br><br>' +
              '<ul style="text-align: left;">' +
              '<li>No ads when launching the application</li>' +
              '<li>Validation of bets without viewing advertising</li>' +
              '<li>Credits offered every day automatically (from 100 to 550 credits/day depending on your level)</li>' +
              '<li>The application in the colors of a champion with the GOLD theme</li>' +
              '<li>Premium Gold badge next to your nickname on the leaderboard</li>' +
              '</ul>' +
              '<br>This is a one-time purchase, no renewals will be made when the Premium Account expires.'
        }
      ].concat(this.products)
    },
    acheterCredits (stripeId) {
      this.credits = [
        {
          price: stripeId,
          quantity: 1,
        }
      ]
      // You will be redirected to Stripe's secure checkout page
      this.$refs.creditsRef.redirectToCheckout();
    },
    getUserInfo () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/user/',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => {
        this.user = response.data
      }).finally(() => {
        this.getProducts(this.user.niveau.id)
      });
    },
    getProducts (niveau) {
      if (niveau !== undefined) {
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_ROOT_URL + '/api/catalogue/niveau/' + niveau,
          headers: {
            'Authorization': 'Bearer ' + getAuthToken()
          }
        }).then((response) => this.products = response.data);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '~mdb-ui-kit/css/mdb.min.css';
.container-card {

    position: relative;
    margin: auto;
    overflow: hidden;
    width: 520px;
    height: 350px;
    background: white;
    box-shadow: 5px 5px 15px rgba(pink, .5);
    border-radius: 10px;

  p {
    font-size: 0.6em;
    color: pink;
    letter-spacing: 1px;
  }

  h1 {
    font-size: 1.2em;
    color: black;
    margin-top: -5px;
  }

  h2 {
    color: black;
    margin-top: -5px;
  }

  img {
    width: 290px;
    margin-top: 47px;
  }

  .product {
    position: absolute;
    width: 40%;
    height: 100%;
    top: 10%;
    left: 60%;
  }

  .desc {
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 17px;
    color: black;
    font-size: .7em;
    line-height: 1.6em;
    margin-right: 25px;
    text-align: justify;
  }

  button {
    background: darken(white, 10%);
    padding: 10px;
    display: inline-block;
    outline: 0;
    border: 0;
    margin: -1px;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    cursor: pointer;
    &:hover {
      background: pink;
      transition: all .4s ease-in-out;
    }
  }

  .pick {
    margin-top: 11px;
    margin-bottom:0;
    margin-left: 20px;
  }


  .focus{
    background: pink;
    color: white;
  }
}
</style>
